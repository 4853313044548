import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: () => import('../layouts/AppLayout.vue'),
		children: [
			{
				path: '/',
				name: 'Home',
				component: () => import('../views/Home.vue')
			},

			{
				path: '/products',
				name: 'Products',
				component: () => import('../views/Products.vue')
			},

			{
				path: '/products/:main_category',
				name: 'Products',
				component: () => import('../views/Products.vue')
			},

			{
				path: '/products/:main_category/:sub_category',
				name: 'Products',
				component: () => import('../views/Products.vue')
			},

			{
				path: '/product/:id',
				name: 'Product',
				component: () => import('../views/Product.vue')
			},

			{
				path: '/profile',
				name: 'Profile',
				component: () => import('../views/Profile.vue')
			},

			{
				path: '/messages',
				name: 'Messages',
				component: () => import('../views/Messages.vue')
			},

			{
				path: '/message/:id',
				name: 'Message',
				component: () => import('../views/Message.vue')
			},

			{
				path: '/orders',
				name: 'Orders',
				component: () => import('../views/Orders.vue')
			}
			/*{
				path: '/edit_product/:product_id',
				name: 'Edit product',
				component: () => import('../views/EditProduct.vue')
			}*/
		]
	}
];

const router = new VueRouter({
	mode: 'history',
	routes
});

export default router;
